<script>
import { 
  Bar,
  mixins
} from 'vue-chartjs'
import 'chartjs-plugin-labels';

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  mounted() {
        const self = this
        const option = Object.assign({}, this.options);
        option.scales = {
            yAxes: [{
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, values) {
                        return self.commarize(value, 1000);
                    },
                    beginAtZero: true
                }
            }]
        }
        option.tooltips = {
          enabled: true,
          callbacks: {
            label: ((tooltipItems, chartdata) => {
              return self.commarized(tooltipItems.yLabel);
            })
          }
        }
        this.renderChart(this.chartData, option)
    },
    methods: {
      commarize(value, min) {
        min = min || 1e3;
        // Alter numbers larger than 1k
        if (value >= min) {
            var units = ["k", "M", "B", "T"];

            var order = Math.floor(Math.log(value) / Math.log(1000));

            var unitname = units[(order - 1)];
            var num = value / 1000 ** order;

            // output number remainder + unitname
            return num + unitname
        }

        // return formatted original number
        return value.toLocaleString()
      },
      commarized(value) {
        var numeral = require("numeral");
        return numeral(value).format("0,0[.]00");
      }
    }
}
</script>
