<template>
<div>
    <v-container class="container--fluid grid-list-md text-center" style="margin-bottom: 50px">
        <h1>Terminal Module Dashboard</h1>
        
        <!-- <v-container class="container--fluid grid-list-md text-center">
            <h1>Terminal Dashboard</h1>
        </v-container> -->
        <v-container class="container--fluid grid-list-md text-center">
            <h3>Terminal Status</h3>
        </v-container>
        <pie-chart v-if="loaded && pieChartDataCount && hasPermission('ROLE_TDM_VIEW_TERMINAL_DASHBOARD')" :chart-data="pieChartDataCount" :options="options"/>
        <v-container class="container--fluid grid-list-md text-center">
        <br/>    
            <h3>Latest Updated Terminals</h3>
        </v-container>
        <v-data-table :hide-default-header="true" :headers="headers" :items="displayTerminals" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalTerminals" :footer-props="{'items-per-page-options': [totalTerminals]}" class="elevation-2 mt-4 px-4" :hide-default-footer="true" :disable-pagination="true">
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr :colspan="headers.length">
                        <th v-for="header in headers" :key="header.text">
                            <span class="d-flex justify-center">{{header.text}}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                        <td> <span class="d-flex justify-center"> {{ item.terminalId }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.serialNum }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.type }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.model }} </span></td>
                        <td v-if="item.status == `IN_SERVICE`" bgcolor="#64E986"> <span class="d-flex justify-center"> {{ item.status }} </span></td>
                        <td v-else-if="item.status == `SUPERVISORY`" bgcolor="#FFDB58"> <span class="d-flex justify-center"> {{ item.status }} </span></td>
                        <td v-else bgcolor="#F75D59"> <span class="d-flex justify-center"> {{ item.status }} </span></td>
                        
                    </tr>
                </tbody>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Found no results.
                </v-alert>
            </template>
        </v-data-table>
        <br/>
        <v-container class="container--fluid grid-list-md text-center">
            <h3>Transaction Data</h3>
        </v-container>
        <pie-chart v-if="loaded && pieChartData" :chartdata="pieChartData" :options="options" />
        <v-row class="ma-1">
            <v-col cols="12" md="6">
                <line-chart v-if="loaded && transactionCountData" :chart-data="transactionCountData" :options="options" />
            </v-col>
            <v-col cols="12" md="6">
                <line-chart v-if="loaded && transactionAmountData" :chart-data="transactionAmountData" :options="options" />
            </v-col>
        </v-row>
        <v-row class="ma-1">
            <v-col cols="12" md="6">
                <horizontal-bar-chart v-if="loaded && barChartDataCount" :chart-data="barChartDataCount" :options="options" />
            </v-col>
            <v-col cols="12" md="6">
                <horizontal-bar-chart v-if="loaded && barChartDataAmount" :chart-data="barChartDataAmount" :options="options" />
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import LineChart from '@/views/components/LineChart.vue';
import HorizontalBarChart from '@/views/components/HorizontalBarChart.vue';
import BarChart from '@/views/components/BarChart.vue';
import PieChart from '@/views/components/PieChart.vue';
import Loading from '@/views/components/Loading.vue'
import API from '@/api/API';
import moment from 'moment';
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Dashboard',
    components: {
        LineChart,
        PieChart,
        HorizontalBarChart,
        BarChart,
        Loading
    },
    computed: {
        ...mapGetters([
            'attributes',
            'authorities',
            'roles'
        ])
    },
    data: () => ({
        headers: [],
        displayTerminals: [],
        remainderTerminals: [],
        loading: true,
        loadingMessage: "",
        loadingDialog: false,
        loadingFinished: false,
        page: 1,
        totalTerminals: 0,
        numberOfPages: 0,
        options: {
            rowsPerPage: 1000
        },
        details: {
            title: '',
            item: {}
        },
        loaded: false,
        chartdata: null,
        transactionAmountData: null,
        transactionCountData: null,
        pieChartData: null,
        barChartDataAmount: null,
        pieChartDataCount: null,
        barChartDataCount: null,
        barChartDataAmount: null,
        barChartDataStoreCount: null,
        barChartDataStoreAmount: null,
        options: {
            responsive: true,
            maintainAspectRatio: false
        }
    }),
    async mounted() {
        this.loaded = false
        this.setHeaders()
        await this.queryChartData()
        this.loaded = true
    },
    watch: {
        options: {
            handler() {
                console.log('1')
                this.queryData()
            },
        },
        deep: true,
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        hasRole(role) {
          if(this.roles.some(x => x.name === role)){
            return true
          }
          return false
        },
        // redirect(data) {
        //     console.log(data.value)
        // },
        getMap(list, value) {
            var map = []
            for (var i = 0; i < list.length; i++) {
                if (list[i][value] === undefined) {
                    map.push(0)
                } else {
                    map.push(list[i][value]);
                }
            }
            return map
        },
        async queryChartData() {
            try {
                if (this.hasPermission('ROLE_TDM_VIEW_TERMINAL_DASHBOARD')) {
                    console.log("Dashboard - pie chart")
                    const transactionCountList = await API.getDailyTerminalSummary()
                    console.log(`Pie chart: ${transactionCountList}`)
                    if (transactionCountList.error) {
                        console.log(transactionCountList.error)
                    } else {
                        this.pieChartDataCount = {
                            labels: ['In Service', 'Offline', 'Supervisory'],
                            datasets: [{
                                backgroundColor: ["#41B883", "#E46651", "#FFDB58"],
                                data: [
                                    transactionCountList.inservice,
                                    transactionCountList.offline,
                                    transactionCountList.supervisory
                                ]
                            }]
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
            try {
                const limit = 7 /*7 days of transaction*/
                var transactionList = undefined
                var totals = "pastSummary"

                console.log("Admin Dashboard")
                transactionList = await API.getTransactionsTotal(limit)
                console.log("transactionList: " +transactionList);
                if (transactionList.error) {
                    console.log(transactionList.error)
                } else {
                    const listOfDataName = [...new Set(transactionList[totals].map(it => it.transactionDate))];
                    //const listOfTransactionAmount = [...new Set(transactionList[totals].map(it => it.transactionAmount))].reverse();
                    const listOfTransactionAmount = this.getMap(transactionList[totals], 'amount')
                    //const listOfTransactionAmountAvg = [...new Set(transactionList[totals].map(it => it.amountAverage))].reverse();
                    const listOfTransactionAmountAvg = this.getMap(transactionList[totals], 'amountAverage')
                    //const listOfTransactionCount = [...new Set(transactionList[totals].map(it => it.transactionCount))].reverse();
                    const listOfTransactionCount = this.getMap(transactionList[totals], 'count')
                    //const listOfTransactionCountAvg = [...new Set(transactionList[totals].map(it => it.countAverage))].reverse();
                    const listOfTransactionCountAvg = this.getMap(transactionList[totals], 'countAverage')
                    const amountDataSets = [{
                        label: 'Transaction Value',
                        backgroundColor: '#f87979',
                        data: listOfTransactionAmount
                    }]
                    const countDataSets = [{
                        label: 'Transaction Count',
                        backgroundColor: '#f87979',
                        data: listOfTransactionCount
                    }]
                    amountDataSets.push({
                        label: 'Average',
                        backgroundColor: '#5380a9',
                        data: listOfTransactionAmountAvg
                    })
                    countDataSets.push({
                        label: 'Average',
                        backgroundColor: '#5380a9',
                        data: listOfTransactionCountAvg
                    })
                    this.transactionAmountData = {
                        labels: listOfDataName,
                        datasets: amountDataSets
                    }
                    this.transactionCountData = {
                        labels: listOfDataName,
                        datasets: countDataSets

                    }
                }
            } catch (e) {
                console.log(e)
            }
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                const limit = itemsPerPage
                const terminalListResponse = await API.getLatestUpdatedTerminal(pageNumber, limit)
                if (!terminalListResponse || terminalListResponse.error) {
                    //error getting data
                    console.log(`${terminalListResponse.error}`)
                } else {
                    var filteredList = terminalListResponse.terminals
                    if (page > 1 && this.remainderTerminals.length > 0) {
                        filteredList = this.remainderTerminals.concat(filteredList)
                    }
                    this.totalTerminals = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayTerminals = filteredList.slice(0, end)
                    this.remainderTerminals = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalTerminals / itemsPerPage
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        setHeaders() {
            var headers = [{
                    text: 'Terminal ID',
                    value: 'terminalId'
                },
                {
                    text: 'Serial Number',
                    value: 'serialNum'
                },
                {
                    text: 'Type',
                    value: 'type'
                },
                {
                    text: 'Model',
                    value: 'model'
                },
                {
                    text: 'Status',
                    value: 'status'
                }
            ]
            this.headers = headers
        },
        sortBy(array, field, type = 'asc') {
            if (type === 'asc') {
                const sorted = array.slice(0).sort(function (a, b) {
                    return (a[field] > b[field]) ? 1 : (a[field] < b[field]) ? -1 : 0;
                });
                return sorted
            } else {
                const sorted = array.slice(0).sort(function (a, b) {
                    return (a[field] < b[field]) ? 1 : (a[field] > b[field]) ? -1 : 0;
                });
                return sorted
            }
        }
    }
};
</script>
